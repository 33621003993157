.loginLogo{
    width: 50%;
}

.login{
    width: 65%;
}

video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.loginButton{
    position: absolute;
    width: 100%;
    height: 92%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content:end;
    align-items: center;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.20);
}